var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.masterCallMaxCount
    ? _c(
        "div",
        { staticClass: "pMyPage-Inner px-2 px-md-10" },
        [
          _c(
            "div",
            {
              staticClass: "mt-4 mb-1 d-flex justify-space-between",
              attrs: { id: "listTop" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "fs-xl pl-4 font-weight-bold d-flex align-center",
                },
                [
                  _vm._v("\n      マスターコール"),
                  _c("span", { staticClass: "fs-s font-weight-medium" }, [
                    _vm._v(
                      "（" +
                        _vm._s(
                          _vm.masterCallMasterList.length +
                            _vm.masterCallChangeCount
                        ) +
                        "/" +
                        _vm._s(_vm.masterCallMaxCount) +
                        "）"
                    ),
                  ]),
                  _c("Hint", { attrs: { contentClass: "ml-2 mb-0" } }, [
                    _c("p", [
                      _vm._v(
                        "\n          クリエイターの受注開始時に通知メールを受け取れるサービスです。"
                      ),
                      _c("br"),
                      _vm._v("\n          詳しくは"),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://support.alpaca-connect.com/hc/ja/articles/13178198194969",
                            target: "_blank",
                          },
                        },
                        [_vm._v("ヘルプセンター")]
                      ),
                      _vm._v("をご確認ください。\n        "),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-list",
            [
              _vm._l(_vm.masterCallMasterList, function (item, index) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: item.masterId,
                      attrs: {
                        to: {
                          name: "master-detail",
                          params: {
                            masterKbn: item.masterKbn,
                            masterId: item.masterId,
                          },
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c("BaseIcon", {
                            attrs: { type: "master", itemId: item.masterId },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.masterName) +
                                  "\n          "
                              ),
                            ]
                          ),
                          item.juchuComment
                            ? _c(
                                "div",
                                { staticClass: "my-1" },
                                [
                                  _c(
                                    "v-sheet",
                                    {
                                      staticClass:
                                        "py-1 px-2 px-md-3 fs-xs rounded d-inline-block",
                                      attrs: { color: _vm.ColorGray2 },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.juchuComment) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("MasterCallBtn", {
                            attrs: {
                              "max-count": _vm.masterCallMaxCount,
                              "master-name": item.masterName,
                              "master-id": item.masterId,
                              "master-call-master-list":
                                _vm.masterCallMasterList,
                            },
                            on: {
                              updateMasterCallList: _vm.updateMasterCallList,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  index + 1 < _vm.masterCallMasterList.length
                    ? _c("v-divider", {
                        key: item.masterId + "_hr",
                        staticClass: "my-1 mx-4",
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
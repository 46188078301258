<template>
  <div class="mShop-Body">
    <Loading v-if="loading"></Loading>
    <!--合成用マスタリスト-->
    <!-- itemName | description | 必要素材　｜作成コスト　｜　作成ボタン -->
    <v-sheet
      v-else
      max-width="1200"
      class="mx-auto px-5 pb-10 py-4"
    >
      <v-row>
        <v-col cols="12" class="pl-8 text-left fs-xl font-weight-bold">
          <v-icon class="mb-1">
            note
          </v-icon>
          作成可能アイテム一覧
        </v-col>
      </v-row>
      <v-row dense class="mb-4">

        <v-btn
          outlined
          small
          depressed
          class="fs-xs ml-3 mt-1"
          :color="ColorPrimary"
          @click="$refs.historyDialog.open()"
        >
          作成履歴
        </v-btn>
        <v-spacer/>
        <v-menu
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-card max-width="450">
            <v-row dense class="ml-5 mr-5">
              <v-switch
                v-model="levelUpFlg"
                label="レベルアップしたアイテムを使用する"
              >
              </v-switch>
            </v-row>
            <v-row dense class="ml-5 mr-5">
              <v-switch
                v-model="originalizedFlg"
                label="オリジナル化したアイテムを使用する"
              >
              </v-switch>
            </v-row>
          </v-card>
        </v-menu>
      </v-row>
      <div>
        <v-row>
          <v-col
            cols="12"
            v-for="(item, index) in itemSynthMasterList"
            :key="index"
          >
            <v-sheet
              rounded outlined class="mt-6 mx-auto" max-width="600">
              <!-- カテゴリチップ -->
              <v-chip :color="ColorGray2" x-small class="ml-2 mt-n7 fs-xxs"
              >交換アイテム
              </v-chip>
              <v-row class="mx-2 mt-n3" dense>
                <!-- アイコン -->
                <v-col cols="2"
                       class="text-center"
                >
                  <ItemIcon
                    class="mx-auto"
                    :size="25"
                    :defaultIcon="item.iconUrl"
                  />
                </v-col>
                <v-col cols="10" class="mt-n1">
                  <!-- アイテム名-->
                  <v-row class="font-weight-bold fs-xs">
                    {{ item.itemName }} {{ item.itemNum > 1 ? 'x' + item.itemNum : '' }}
                  </v-row>
                  <v-row class="fs-xs">
                    <!-- 説明 -->
                    {{ item.description }}
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="mx-4"/>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 12">

                  <v-sheet class="mx-2
               text-center fs-xs
               mb-2"
                           rounded
                           :color="ColorGray2"
                  >
                    必要素材
                  </v-sheet>
                  <span
                    class="font-weight-bold fs-s ml-4"
                    v-for="(material, index) in getNeedsMaterial(item.itemSynthId)" :key="index">
                {{ material }}
                <br/>
              </span>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 12">
                  <v-sheet class="mx-2
               text-center fs-xs
               mb-2" :color="ColorGray2"
                           rounded
                  >
                    作成コスト
                  </v-sheet>
                  <div v-if="getCostForCreate(item) && getCostForCreate(item).length>0">
                <span
                  class="fs-s ml-4"
                  v-for="(cost, index) in getCostForCreate(item)" :key="index">
                  {{ cost.count | addComma }} <span class="fs-xxs">
                  {{ cost.name }}
                </span>
                  <br/>
                </span>
                  </div>
                  <div v-else>
                    <div class="fs-s ml-4">
                      なし
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="mx-4 my-3"
              >
                <v-skeleton-loader
                  type="card"
                  class="mx-auto"
                  width="100%"
                  height="30"
                  v-if="item.createCheck === undefined || item.createCheck === null"
                />
                <v-btn
                  v-else
                  small
                  depressed
                  block
                  max-width="300"
                  class="font-weight-bold"
                  :color="ColorSecondary"
                  :dark="item.createCheck"
                  :disabled="!item.createCheck"
                  @click="selectedItem = item; $refs.confirmDialogOk.open()"
                >
                  アイテムを作る
                </v-btn>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>

      </div>
    </v-sheet>
    <AlertDialog
      ref="confirmDialogOk"
      title="アイテム作成"
      :text="selectedItem.itemName + 'を作成しますか？'"
      @alertDialogReturn="createItem"
    />
    <ItemSynthHistoryDialog
      ref="historyDialog"
      :history-list="historyList"
    />
  </div>
</template>


<script>
import axios from 'axios'
import ItemIcon from '@/components/ItemIcon.vue'
import AlertDialog from '@/components/AlertDialog.vue'
import Loading from '@/components/Loading.vue'
import ItemSynthHistoryDialog from '@/views/world/itemSynth/ItemSynthHistoryDialog.vue'

export default {
  name: 'itemSynthTop',
  components: {ItemSynthHistoryDialog, Loading, AlertDialog, ItemIcon},
  inject: ['snack'],
  data() {
    return {
      loading: false,
      historyList: [],
      historyCount: 0,
      page: 0,
      size: 20,
      hoyuItemList: [],
      itemSynthMasterList: [],
      itemSynthMaterialList: [],
      allHoyuItemList: [],
      selecteditemSynthId: 0,
      levelUpFlg: false,
      originalizedFlg: false,
      alpacaCoin: 0,
      llamaPoint: 0,
      computedAllHoyuItemList: [],
      selectedItem: {},
      selectedMaterialList: [],
      selectedCostList: []
    }
  },
  async created() {
    await this.refresh()
  },

  watch: {
    tokenList: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$store.dispatch('tokenList', {
          tokenList: val
        })
      }
    },
    levelUpFlg: {
      deep: true,
      handler(val) {
        this.makeComputedAllHoyuItemList()
      }
    },
    originalizedFlg: {
      deep: true,
      handler(val) {
        this.makeComputedAllHoyuItemList()
      }
    }
  },
  computed: {
    // 下層共有用トークン情報 TODO:storeに入れる
    tokenList() {
      return {
        alpacaCoin: this.alpacaCoin,
        llamaPoint: this.llamaPoint,
        worldToken1: this.worldToken1,
        worldTokenTsukaName1: this.worldTokenTsukaName1,
        worldToken2: this.worldToken2,
        worldTokenTsukaName2: this.worldTokenTsukaName2,
        worldToken3: this.worldToken3,
        worldTokenTsukaName3: this.worldTokenTsukaName3,
        worldToken4: this.worldToken4,
        worldTokenTsukaName4: this.worldTokenTsukaName4
      }
    }
  },
  methods: {
    async refresh() {
      /* ロード開始 */
      this.loading = true
      try {
        await this.getItemMasterList()
        await this.allHoyuItem()
        await this.getCharacterInfo()

        await this.$store.dispatch('statusChange', {statusChange: '3'})
      } catch (error) {
        this.snack('error', this.Msg_timeout)
      } finally {
        //1s待ってからmakeComputedAllHoyuItemListを実行
        setTimeout(() => {
          this.makeComputedAllHoyuItemList()
        }, 1000)
        this.loading = false
      }
    },
    makeComputedAllHoyuItemList() {
      //allHoyuItemListを加工
      //levelUpFlg、originalizedFlgによってallHoyuItemListをフィルタリング
      //まずlevel=1,originalize=0のものを取得
      //このオブジェクトをshallow copyではなくdeep copyする
      //      let allHoyuItemList = this.allHoyuItemList.filter(item => item.level === 1 && item.originalize === 0)

      let allHoyuItemList = JSON.parse(JSON.stringify(this.allHoyuItemList.filter(item => item.level === 1 && item.originalize === 0)))
      //levelUpFlgがtrueの場合、level>=2のものをitemCodeで一致するものを取得し、allHoyuItemListに入っているshojiSuを加算
      if (this.levelUpFlg) {
        const levelUpItems = this.allHoyuItemList.filter(item => item.level >= 2 && item.originalize === 0)
        for (const levelUpItem of levelUpItems) {
          const item = allHoyuItemList.find(item => item.itemCode === levelUpItem.itemCode)
          if (item) {
            item.shojiSu += levelUpItem.shojiSu
          } else {
            allHoyuItemList.push(levelUpItem)
          }
        }
      }

      //originalizedFlgがtrueの場合、originalize=1のものをitemCodeで一致するものを取得し、allHoyuItemListに入っているshojiSuを加算
      if (this.originalizedFlg) {
        const originalizedItems = this.allHoyuItemList.filter(item => item.originalize === 2 || item.originalize === 1)
        for (const originalizedItem of originalizedItems) {
          const item = allHoyuItemList.find(item => item.itemCode === originalizedItem.itemCode)
          if (item) {
            item.shojiSu += originalizedItem.shojiSu
          } else {
            allHoyuItemList.push(originalizedItem)
          }
        }
      }

      this.computedAllHoyuItemList = allHoyuItemList

      //itemSynthMasterListの作成可能フラグを更新
      for (const item of this.itemSynthMasterList) {
        item.createCheck = this.createCheck(item)
        //DOM更新
        this.$forceUpdate()
      }

    },
    getCharacterInfo: async function () {
      //キャラクター情報取得
      try {
        const res = await axios.get(this.serverPath + '/api/WorldTop/AppView', {
          params: {
            worldId: this.$store.state.worldId,
            userId: this.$store.state.userId,
            characterId: this.$store.state.characterId
          }
        })
        //ログインキャラクターのユーザーIDが一致しない場合
        if (res.status == 203) {
          this.$store.dispatch('characterId', '')
          this.$store.dispatch('worldId', this.worldId)
        } else if (res.data) {
          this.worldItemUseInfo = res.data.worldItemUseInfo

          if (res.data.alpacaCoin != null) {
            this.alpacaCoin = res.data.alpacaCoin
          }
          if (res.data.llamaPoint != null) {
            this.llamaPoint = res.data.llamaPoint
          }

          if (res.data.worldToken1 != null) {
            this.worldToken1 = res.data.worldToken1
          }
          if (res.data.worldTokenTsukaName1 != null) {
            this.worldTokenTsukaName1 = res.data.worldTokenTsukaName1
          }
          if (res.data.worldToken2 != null) {
            this.worldToken2 = res.data.worldToken2
          }
          if (res.data.worldTokenTsukaName2 != null) {
            this.worldTokenTsukaName2 = res.data.worldTokenTsukaName2
          }
          if (res.data.worldToken3 != null) {
            this.worldToken3 = res.data.worldToken3
          }
          if (res.data.worldTokenTsukaName3 != null) {
            this.worldTokenTsukaName3 = res.data.worldTokenTsukaName3
          }
          if (res.data.worldToken4 != null) {
            this.worldToken4 = res.data.worldToken4
          }
          if (res.data.worldTokenTsukaName4 != null) {
            this.worldTokenTsukaName4 = res.data.worldTokenTsukaName4
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    //所持コインの確認
    getCoinzandaka: async function () {
      await axios({
        method: 'get',
        url: this.serverPath + '/api/coin/getCoins'
      }).then((response) => {
        this.alpacaCoin = response.data.alpacaCoin
        this.llamaPoint = response.data.llamaPoint
      })
    },
    createCheck(item) {
      //作成可能かどうかの判定
      //必要素材があるかどうか
      const materialList = this.itemSynthMaterialList.filter(material => material.itemSynthId === item.itemSynthId)

      //アイテムの個数チェック
      for (const material of materialList) {
        const needCount = material.needCount
        const hoyuItem = this.computedAllHoyuItemList.find(item => item.itemCode === material.itemCode)


        if (!hoyuItem || hoyuItem.shojiSu < needCount) {
          return false
        }
      }

      //コストがあるかどうか
      if (item.coin) {

        //alpacaCoinかllamaPointが足りていれば作成可能
        if (item.coin > this.alpacaCoin && item.coin > this.llamaPoint) {
          return false
        }

      }

      if (item.worldToken1) {
        if (this.worldToken1 < item.worldToken1) {
          return false
        }
      }

      if (item.worldToken2) {
        if (this.worldToken2 < item.worldToken2) {
          return false
        }
      }


      if (item.worldToken3) {
        if (this.worldToken3 < item.worldToken3) {
          return false
        }
      }


      if (item.worldToken4) {
        if (this.worldToken4 < item.worldToken4) {
          return false
        }
      }

      return true
    },
    getCostForCreate(itemSynthMaster) {

      const worldTokenNameMaster = this.$store.state.parameterInfo[this.$store.state.worldId].worldItemNameMaster

      //worldToken1
      //itemSynthMaster.worldToken1があれば
      const consAry = []
      if (itemSynthMaster.worldToken1) {

        const worldToken1 = itemSynthMaster.worldToken1
        const worldToken1Name = worldTokenNameMaster['worldToken1']

        consAry.push({
          type: 'worldToken1',
          name: worldToken1Name,
          count: worldToken1,
          icon: ''
        })
      }

      //worldtoken2
      if (itemSynthMaster.worldToken2) {
        const worldToken2 = itemSynthMaster.worldToken2
        const worldToken2Name = worldTokenNameMaster['worldToken2']

        consAry.push({
          type: 'worldToken2',
          name: worldToken2Name,
          count: worldToken2,
          icon: ''
        })
      }

      //worldtoken3
      if (itemSynthMaster.worldToken3) {
        const worldToken3 = itemSynthMaster.worldToken3
        const worldToken3Name = worldTokenNameMaster['worldToken3']

        consAry.push({
          type: 'worldToken3',
          name: worldToken3Name,
          count: worldToken3,
          icon: ''
        })
      }

      //worldToken4
      if (itemSynthMaster.worldToken4) {
        const worldToken4 = itemSynthMaster.worldToken4
        const worldToken4Name = worldTokenNameMaster['worldToken4']

        consAry.push({
          type: 'worldToken4',
          name: worldToken4Name,
          count: worldToken4,
          icon: ''
        })
      }

      //AC/LP
      if (itemSynthMaster.coin) {
        consAry.push({
          type: 'coin',
          name: 'AC/LP',
          count: itemSynthMaster.coin,
          icon: ''
        })
      }

      return consAry
    },
    getNeedsMaterial(itemSynthId) {
      const materialList = this.itemSynthMaterialList.filter(material => material.itemSynthId === itemSynthId)

      //itemNameとneedCountを取得し、itemName x needNum の形式で箇条書きにして返す
      const materialAry = []

      for (const material of materialList) {
        const materialName = material.itemName
        const needCount = material.needCount
        materialAry.push(materialName + ' x ' + needCount)
      }

      return materialAry

    },
    allHoyuItem: async function () {

      try {
        /* 全ての保有アイテム検索 */
        const res = await axios.get(
          this.serverPath + '/api/SobiItemSettings/allHoyuItem',
          {
            params: {
              worldId: this.$store.state.worldId,
              characterId: this.$store.state.characterId
            }
          }
        )
        if (res.data) {
          this.allHoyuItemList = res.data.allHoyuItemList
          this.makeComputedAllHoyuItemList()
        }
      } catch (error) {
        /* エラー時スナックバー */
        this.snack('error', this.Msg_timeout)
      }
    },

    getItemMasterList: async function () {

      axios.get(this.serverPath + '/api/ItemSynth/getList/' + this.$store.state.characterId)
        .then(response => {
          this.itemSynthMasterList = response.data.itemSynthMasterList
          this.itemSynthMaterialList = response.data.itemSynthMaterialList
        })
        .catch(error => {
          console.log(error)
        })

    },
    createItem: async function (answer) {
      console.log(this.selectedItem)

      if (!answer) {
        return
      }

      axios.post(this.serverPath + '/api/ItemSynth/create', {
        characterId: this.$store.state.characterId,
        itemSynthId: this.selectedItem.itemSynthId,
        originalizedFlg: this.originalizedFlg,
        levelUpFlg: this.levelUpFlg
      })
        .then(response => {
          this.snack('success', 'アイテムを作成しました')
          this.refresh()
        })
        .catch(error => {
          this.snack('error', 'アイテムの作成に失敗しました')
        })
    }
  }
}
</script>


<template>
  <div class="pMyPage-Inner px-2 px-md-10" v-if="masterCallMaxCount">
    <div id="listTop" class="mt-4 mb-1 d-flex justify-space-between">
      <div class="fs-xl pl-4 font-weight-bold d-flex align-center">
        マスターコール<span class="fs-s font-weight-medium"
          >（{{ masterCallMasterList.length + masterCallChangeCount }}/{{
            masterCallMaxCount
          }}）</span
        >
        <Hint contentClass="ml-2 mb-0">
          <p>
            クリエイターの受注開始時に通知メールを受け取れるサービスです。<br />
            詳しくは<a
              href="https://support.alpaca-connect.com/hc/ja/articles/13178198194969"
              target="_blank"
              >ヘルプセンター</a
            >をご確認ください。
          </p>
        </Hint>
      </div>
    </div>

    <v-list>
      <template v-for="(item, index) in masterCallMasterList">
        <v-list-item
          :to="{
            name: 'master-detail',
            params: {
              masterKbn: item.masterKbn,
              masterId: item.masterId,
            },
          }"
          :key="item.masterId"
        >
          <v-list-item-avatar>
            <BaseIcon type="master" :itemId="item.masterId"></BaseIcon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.masterName }}
            </v-list-item-title>
            <div v-if="item.juchuComment" class="my-1">
              <v-sheet
                :color="ColorGray2"
                class="py-1 px-2 px-md-3 fs-xs rounded d-inline-block"
              >
                {{ item.juchuComment }}
              </v-sheet>
            </div>
          </v-list-item-content>

          <v-list-item-action>
            <MasterCallBtn
              :max-count="masterCallMaxCount"
              :master-name="item.masterName"
              :master-id="item.masterId"
              :master-call-master-list="masterCallMasterList"
              @updateMasterCallList="updateMasterCallList"
            ></MasterCallBtn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          class="my-1 mx-4"
          :key="`${item.masterId}_hr`"
          v-if="index + 1 < masterCallMasterList.length"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import axios from "axios";
import MasterCallBtn from "@/components/MasterCallBtn";

export default {
  name: "masterCallManagement",
  components: {
    MasterCallBtn,
  },
  data() {
    return {
      loading: false,
      snackbarType: "success",
      snackbarMsg: "保存しました。",
      sorting: false,
      masterCallMasterList: [],
      masterCallMaxCount: 0,
      masterCallChangeCount: 0,
      smallTab: "",
    };
  },

  created() {
    this.getMasterCallMasterList();
  },

  methods: {
    getMasterCallMasterList: async function () {
      try {
        const res = await axios.get(
          this.serverPath + "/api/MasterCall/getListForManagement"
        );
        this.masterCallMasterList = res.data.masterCallMasterList;
        this.masterCallMaxCount = res.data.maxCount;
      } catch (error) {
        console.log(error);
        console.log(this.Msg_timeout);
      }
    },
    updateMasterCallList(val) {
      if (val.type == "delete") {
        this.masterCallChangeCount--;
      } else {
        this.masterCallChangeCount++;
      }
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/import.scss";

.pMyPage {
  &-Inner {
    @include baseBox;
    max-width: 1000px;
  }
}
</style>

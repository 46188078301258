<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title
        class="wc-SimpleHeader pa-0 lh-l pl-4"
        height="56px"
        :color="ColorGray2"
      >
        作成履歴
        <v-spacer />
        <v-icon
          class="mr-3"
          @click="closeHistoryDialog">
          close
        </v-icon>
      </v-card-title>
      <v-card-text class="fs-xs">
        <div v-if="loading">
          <v-skeleton-loader type="table" :loading="loading" />
        </div>
        <!---データが無い場合--->
        <v-simple-table v-if="historyList.length === 0 && !loading">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center">履歴ID</th>
              <th class="text-center">アイテム名</th>
              <th class="text-center">作成日時</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="3" class="text-center">データがありません</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center">履歴ID</th>
              <th class="text-center">アイテム名</th>
              <th class="text-center">作成日時</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(history, index) in historyList" :key="index">
              <td class="text-center">{{ history.itemSynthHistoryId }}</td>
              <td class="text-center">{{ history.itemName }}</td>
              <td class="text-center">{{ moment(history.createdAt) }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!---ページネーション--->
        <v-pagination

          color="var(--gray-6)"
          class="sl-Pagination"
          v-model="page"
          circle
          :length="Math.ceil(count / size)"
          @input="getHistory(page, size)">

        </v-pagination>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

export default {
  name: 'ItemSynthHistoryDialog',
  props: {},
  data() {
    return {
      page: 1,
      size: 20,
      historyList: [],
      loading: false,
      dialog: false,
      count: 0
    }
  },
  async created() {
    await this.getHistory(this.page, this.size)
  },
  methods: {
    getHistory: function(page, size) {
      /* シナリオ状態検索 */
      const res = axios.get(this.serverPath + '/api/ItemSynth/history/' + this.$store.state.characterId, {
        params: {
          page: page,
          size: size
        }
      })

      res.then((res) => {
        res.data.itemSynthHistoryList.forEach((history) => {
          this.historyList.push(history)
        })
        this.count = res.data.count
      })
    },
    closeHistoryDialog() {
      this.dialog = false
    },
    open() {
      this.dialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/import.scss";

.sl {
  &-Pagination {
    ::v-deep .v-pagination__navigation {
      box-shadow: none !important;
    }

    ::v-deep .v-pagination__item {
      box-shadow: none !important;
    }
  }
}
</style>

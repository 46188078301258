var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-10" },
    [
      _c(
        "v-card",
        { staticClass: "pTop mb-8 pt-4", attrs: { flat: "" } },
        [
          _c("div", { staticClass: "text-center fs-xxl font-weight-bold" }, [
            _vm._v("SpecialThanks"),
          ]),
          _c("div", { staticClass: "text-center font-weight-bold" }, [
            _vm._v("\n      沢山のご支援をありがとうございました！\n    "),
          ]),
          _c("v-card-text", { staticClass: "text-center fs-xl" }, [
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("近藤豊さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("荒木拓海さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("荒木規佑さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("大熔解さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("駒米たもさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("千葉　智さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("グール・シックさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("梅さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("羽鳥さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("クリスチーネさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("のえりあさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("トルネード一門さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("鏡野ありすさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("甕星さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("真夜さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("往螺おときさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("莉々星さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("夕風ひかりさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("赤羽さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("高瀬さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("いがわさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("水瀬さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("紅茶卿さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("オルキスさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("紋葉さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("はざまっくすさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("じゃこさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("クライム・バレエさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("叢雲いざやさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("天風さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("おとね。さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("もりんちゅさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("中野さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("小野　智史さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("焼き肉店長さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("白夜ゆうさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("元高校球児＆蓮華さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("御ヶ笠さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("アマイさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("トンさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("宮下さつきさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ふぉるさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("桐谷羊治さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("螢さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("くまたにかなめさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ザキオカさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("離岸さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("バージニアさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ヲトヅさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("Meruruさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("karanohaさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("大熊猫さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("haruakiさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ファンドマネージャ新田さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("米田　一機さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("玻璃草さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("霧魔すあまさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("DoLLerさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("御崎なちさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("焼き肉カルビ店長さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("上野素甘さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("秋津島@PBWさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("新井田銀さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ちーせんさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("アクセルさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("大歳さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("はーさんさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("畑下はるこさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("真名木風由さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ひろいさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("にあっちさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("天井裏から見守り隊員さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("小鳥遊さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ユメノン/県 鼎さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("安藤竜水さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("くるみさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("逆凪まことさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("緋打PLさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("風華弓弦さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("水杜さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("きりさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("秋津楸さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("まさとんかつさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("燕//殿下（殿下製品）さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("カーディスさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("しどーさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("古河甚五郎さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("烏龍さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("六車ウィリーさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("セージさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("四方　のあさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("翠玉さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("桃谷の夫さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("momonucoさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("沖田　光さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ふるもちさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("星鏡さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("小鳥遊美空さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("ゴンスケさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("青空さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("鉄耶月さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("櫟 諏訪さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("星月夜☆彡さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("超究極無敵最強魔王ホロウちゃんさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("鳥羽ささみさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("川十重さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("小鳥遊黎さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("色々さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("椎野 宗一郎さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("松木理緒さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("すさびの。さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("峯雪さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("SayDさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("kazayaさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("伊達ユズさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("日暮さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("雨さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("エレナ・クルックさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("キョーコ・クルックさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("Azusaさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("Alabasterさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("水操潤さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("旗村はかいさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("モフエルさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("中田誠司さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("葎さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("桜李さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("八代さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("紅迅 斬華さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("なやさんさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("たままさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("のてこさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("黒桐フダさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("猫井又吉さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("わがし。さま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("水都コータローさま"),
            ]),
            _c("span", { staticClass: "d-inline-block pb-2 px-2 fs-s" }, [
              _vm._v("月神さま"),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
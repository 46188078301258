<template>
  <div class="pb-10">
    <v-card flat class="pTop mb-8 pt-4">
      <div class="text-center fs-xxl font-weight-bold">SpecialThanks</div>
      <div class="text-center font-weight-bold">
        沢山のご支援をありがとうございました！
      </div>
      <v-card-text class="text-center fs-xl">
        <span class="d-inline-block pb-2 px-2 fs-s">近藤豊さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">荒木拓海さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">荒木規佑さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">大熔解さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">駒米たもさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">千葉　智さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">グール・シックさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">梅さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">羽鳥さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">クリスチーネさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">のえりあさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">トルネード一門さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">鏡野ありすさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">甕星さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">真夜さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">往螺おときさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">莉々星さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">夕風ひかりさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">赤羽さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">高瀬さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">いがわさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">水瀬さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">紅茶卿さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">オルキスさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">紋葉さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">はざまっくすさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">じゃこさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">クライム・バレエさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">叢雲いざやさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">天風さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">おとね。さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">もりんちゅさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">中野さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">小野　智史さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">焼き肉店長さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">白夜ゆうさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">元高校球児＆蓮華さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">御ヶ笠さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">アマイさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">トンさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">宮下さつきさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ふぉるさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">桐谷羊治さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">螢さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">くまたにかなめさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ザキオカさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">離岸さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">バージニアさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ヲトヅさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">Meruruさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">karanohaさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">大熊猫さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">haruakiさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s"
          >ファンドマネージャ新田さま</span
        >
        <span class="d-inline-block pb-2 px-2 fs-s">米田　一機さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">玻璃草さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">霧魔すあまさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">DoLLerさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">御崎なちさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">焼き肉カルビ店長さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">上野素甘さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">秋津島@PBWさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">新井田銀さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ちーせんさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">アクセルさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">大歳さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">はーさんさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">畑下はるこさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">真名木風由さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ひろいさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">にあっちさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s"
          >天井裏から見守り隊員さま</span
        >
        <span class="d-inline-block pb-2 px-2 fs-s">小鳥遊さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ユメノン/県 鼎さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">安藤竜水さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">くるみさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">逆凪まことさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">緋打PLさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">風華弓弦さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">水杜さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">きりさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">秋津楸さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">まさとんかつさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s"
          >燕//殿下（殿下製品）さま</span
        >
        <span class="d-inline-block pb-2 px-2 fs-s">カーディスさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">しどーさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">古河甚五郎さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">烏龍さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">六車ウィリーさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">セージさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">四方　のあさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">翠玉さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">桃谷の夫さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">momonucoさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">沖田　光さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ふるもちさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">星鏡さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">小鳥遊美空さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">ゴンスケさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">青空さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">鉄耶月さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">櫟 諏訪さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">星月夜☆彡さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s"
          >超究極無敵最強魔王ホロウちゃんさま</span
        >
        <span class="d-inline-block pb-2 px-2 fs-s">鳥羽ささみさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">川十重さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">小鳥遊黎さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">色々さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">椎野 宗一郎さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">松木理緒さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">すさびの。さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">峯雪さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">SayDさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">kazayaさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">伊達ユズさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">日暮さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">雨さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">エレナ・クルックさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s"
          >キョーコ・クルックさま</span
        >
        <span class="d-inline-block pb-2 px-2 fs-s">Azusaさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">Alabasterさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">水操潤さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">旗村はかいさま</span>
        <!-- <span class="d-inline-block pb-2 px-2 fs-s">鶚さま</span> -->
        <span class="d-inline-block pb-2 px-2 fs-s">モフエルさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">中田誠司さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">葎さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">桜李さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">八代さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">紅迅 斬華さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">なやさんさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">たままさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">のてこさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">黒桐フダさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">猫井又吉さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">わがし。さま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">水都コータローさま</span>
        <span class="d-inline-block pb-2 px-2 fs-s">月神さま</span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "specialThanks",
  components: {},
  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/sass/import.scss";

.pTop {
  background-color: var(--white);
  &-Container {
    @include baseBox;
    max-width: inherit;
  }
}

//カルーセル用スタイル
::v-deep .agile {
  &__actions {
    margin-top: 8px;
  }
  &__nav-button {
    font-size: 24px;
    color: #ccc;
    cursor: pointer;
    background: transparent;
    border: none;
    transition-duration: 0.3s;
  }
  &__nav-button:hover {
    color: #888;
  }
  &__dot {
    margin: 0 10px;
  }
  &__dot button {
    display: block;
    width: 10px;
    height: 10px;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    background-color: #ddd;
    border: none;
    border-radius: 50%;
    transition-duration: 0.3s;
  }
  &__dot--current button,
  &__dot:hover button {
    background-color: #888;
  }
  @include media(1240px) {
    &__slides--regular {
      padding-left: 8%;
    }
    &__slide {
      opacity: 0.6;
      transition: 0.2s ease !important;
      transform: scale(0.98) !important;
    }
    &__slide--active {
      opacity: 1;
      transform: scale(1) !important;
    }
  }
}
::v-deep .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: #fff;
  background-color: #f1c40f;
  h3 {
    font-size: 32px;
    font-weight: 300;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mShop-Body" },
    [
      _vm.loading
        ? _c("Loading")
        : _c(
            "v-sheet",
            {
              staticClass: "mx-auto px-5 pb-10 py-4",
              attrs: { "max-width": "1200" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-8 text-left fs-xl font-weight-bold",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-icon", { staticClass: "mb-1" }, [
                        _vm._v("\n          note\n        "),
                      ]),
                      _vm._v("\n        作成可能アイテム一覧\n      "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-4", attrs: { dense: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fs-xs ml-3 mt-1",
                      attrs: {
                        outlined: "",
                        small: "",
                        depressed: "",
                        color: _vm.ColorPrimary,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.historyDialog.open()
                        },
                      },
                    },
                    [_vm._v("\n        作成履歴\n      ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-menu",
                    {
                      attrs: { "close-on-content-click": false },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { icon: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("menu")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { "max-width": "450" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ml-5 mr-5", attrs: { dense: "" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "レベルアップしたアイテムを使用する",
                                },
                                model: {
                                  value: _vm.levelUpFlg,
                                  callback: function ($$v) {
                                    _vm.levelUpFlg = $$v
                                  },
                                  expression: "levelUpFlg",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "ml-5 mr-5", attrs: { dense: "" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "オリジナル化したアイテムを使用する",
                                },
                                model: {
                                  value: _vm.originalizedFlg,
                                  callback: function ($$v) {
                                    _vm.originalizedFlg = $$v
                                  },
                                  expression: "originalizedFlg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.itemSynthMasterList, function (item, index) {
                      return _c(
                        "v-col",
                        { key: index, attrs: { cols: "12" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "mt-6 mx-auto",
                              attrs: {
                                rounded: "",
                                outlined: "",
                                "max-width": "600",
                              },
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2 mt-n7 fs-xxs",
                                  attrs: {
                                    color: _vm.ColorGray2,
                                    "x-small": "",
                                  },
                                },
                                [_vm._v("交換アイテム\n            ")]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2 mt-n3",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("ItemIcon", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          size: 25,
                                          defaultIcon: item.iconUrl,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-n1",
                                      attrs: { cols: "10" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "font-weight-bold fs-xs",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.itemName) +
                                              " " +
                                              _vm._s(
                                                item.itemNum > 1
                                                  ? "x" + item.itemNum
                                                  : ""
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-row", { staticClass: "fs-xs" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.description) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "mx-4" }),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.$vuetify.breakpoint.smAndUp
                                          ? 6
                                          : 12,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "mx-2\n             text-center fs-xs\n             mb-2",
                                          attrs: {
                                            rounded: "",
                                            color: _vm.ColorGray2,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  必要素材\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.getNeedsMaterial(item.itemSynthId),
                                        function (material, index) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              staticClass:
                                                "font-weight-bold fs-s ml-4",
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(material) +
                                                  "\n              "
                                              ),
                                              _c("br"),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.$vuetify.breakpoint.smAndUp
                                          ? 6
                                          : 12,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "mx-2\n             text-center fs-xs\n             mb-2",
                                          attrs: {
                                            color: _vm.ColorGray2,
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  作成コスト\n                "
                                          ),
                                        ]
                                      ),
                                      _vm.getCostForCreate(item) &&
                                      _vm.getCostForCreate(item).length > 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.getCostForCreate(item),
                                              function (cost, index) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: index,
                                                    staticClass: "fs-s ml-4",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm._f("addComma")(
                                                            cost.count
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "fs-xxs" },
                                                      [
                                                        _vm._v(
                                                          "\n                " +
                                                            _vm._s(cost.name) +
                                                            "\n              "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("br"),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "fs-s ml-4" },
                                              [
                                                _vm._v(
                                                  "\n                    なし\n                  "
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mx-4 my-3" },
                                [
                                  item.createCheck === undefined ||
                                  item.createCheck === null
                                    ? _c("v-skeleton-loader", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          type: "card",
                                          width: "100%",
                                          height: "30",
                                        },
                                      })
                                    : _c(
                                        "v-btn",
                                        {
                                          staticClass: "font-weight-bold",
                                          attrs: {
                                            small: "",
                                            depressed: "",
                                            block: "",
                                            "max-width": "300",
                                            color: _vm.ColorSecondary,
                                            dark: item.createCheck,
                                            disabled: !item.createCheck,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.selectedItem = item
                                              _vm.$refs.confirmDialogOk.open()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                アイテムを作る\n              "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("AlertDialog", {
        ref: "confirmDialogOk",
        attrs: {
          title: "アイテム作成",
          text: _vm.selectedItem.itemName + "を作成しますか？",
        },
        on: { alertDialogReturn: _vm.createItem },
      }),
      _c("ItemSynthHistoryDialog", {
        ref: "historyDialog",
        attrs: { "history-list": _vm.historyList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }